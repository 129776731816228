<template>
  <div v-if="!visible">
    <v-container style="width: 600px">
      <v-card class="my-12 mx-auto text-center" width="600">
        <div class="d-flex justify-center pt-10">
          <logo-image />
        </div>

        <v-card-text v-if="!fileUploaded">
          <div class="text-left px-6 pt-8 pb-4">
            Welcome to Sentry Health's secure file upload portal. Simply follow the instructions
            below to upload a file to our servers.
          </div>
          <v-container>
            <v-row>
              <v-col cols="12" order="1">
                <v-container>
                  <v-card-title class="pt-0 pl-0 black--text font-weight-bold"
                    >2. Enter your information</v-card-title
                  >
                  <v-card-subtitle class="pl-0 justify-start text-left black--text"
                    >This will help us locate your file in our medical records.</v-card-subtitle
                  >
                  <v-row>
                    <v-col>
                      <v-text-field label="First Name" v-model="newUpload.firstName"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field label="Last Name" v-model="newUpload.lastName"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field label="Email" v-model="newUpload.email"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Phone Number"
                        v-mask="masks.phone"
                        v-model="newUpload.phone"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Date of Birth (YYYY/MM/DD)"
                        placeholder="YYYY/MM/DD"
                        v-mask="masks.date"
                        v-model="newUpload.dob"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Health card number"
                        placeholder="####-###-###"
                        v-mask="'####-###-###'"
                        v-model="newUpload.hcn"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12">
                <v-container>
                  <v-card-title class="pa-0 black--text font-weight-bold"
                    >1. Select file(s) to upload
                  </v-card-title>
                  <v-row>
                    <v-col>
                      <div
                        :class="{
                          uploader: true,
                          'uploader--drop': isDropping,
                        }"
                        class="pa-4"
                        @drop.prevent="addFile"
                        @dragover.prevent="isDropping = true"
                      >
                        <v-icon class="uploader-icon">mdi-file-document-multiple-outline</v-icon>
                        <h2>
                          <template v-if="files && files.length">
                            {{ files && files.length > 1 ? 'Files ' : 'File ' }}ready for upload.
                          </template>
                          <template v-else>
                            Drag & Drop
                            <br />
                            File(s) here
                          </template>
                        </h2>
                        <!-- <br /> -->
                        <small class="py-2"> <span v-if="!files || !files.length">or</span> </small>
                        <div class="input-container d-flex justify-center">
                          <v-btn color="primary">
                            <span v-if="files && files.length">Change file(s)</span
                            ><span v-else>Choose file(s)</span>
                            <v-file-input
                              :label="
                                files && files.length ? 'Choose different file' : 'Choose file'
                              "
                              :accept="ACCEPTED_FILE_TYPES"
                              multiple
                              @change="onFileChange"
                            ></v-file-input>
                          </v-btn>
                        </div>
                        <small class="mt-4">Accepted file types: .png, .jpg, .pdf </small>
                      </div>
                      <small v-if="files && files.length" class="text-capitalize">
                        <span class="font-weight-bold"
                          >Will upload {{ `${files.length}` }} file(s): {{ ' ' }}</span
                        >{{ fileName }}
                      </small>
                      <br />
                      <small
                        v-if="errorFiles && errorFiles.length"
                        class="text-capitalize red--text"
                      >
                        <span class="font-weight-bold"
                          >Cannot upload {{ `${errorFiles.length}` }} file(s) with incompatible file
                          types: {{ ' ' }}</span
                        >{{ errorFileNames }}
                      </small>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-card-title class="pt-4 pl-2 black--text font-weight-bold"
              >3. Securely upload your file
            </v-card-title>
            <v-card-subtitle class="pl-2 justify-start text-left black--text"
              >When you press "upload", your file will be securely uploaded and sent to Sentry
              Health.</v-card-subtitle
            >
            <v-row class="pt-4">
              <v-col>
                <v-tooltip top :disabled="!uploadDisabled">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <v-btn
                        color="primary"
                        :disabled="uploadDisabled || loading"
                        @click="doUpload"
                        height="50"
                        width="175"
                      >
                        <div v-if="!loading">
                          <v-icon class="mr-1">mdi-lock</v-icon><span>Upload Now</span>
                        </div>
                        <v-progress-circular v-else indeterminate />
                      </v-btn>
                    </div>
                  </template>
                  <div v-for="field in missingFields" :key="field">{{ field }}</div>
                </v-tooltip>
                <small class="mt-4" v-if="loading"
                  >Uploading files... {{ `${filesUploaded}/${files && files.length}` }}</small
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text v-else>
          <div
            style="border: 1px dashed var(--v-accent-base); width: unset !important"
            class="pa-0 ma-4 mt-7 mb-7"
          >
            <div class="d-flex flex-column pb-8">
              <div class="pt-8">
                <!-- <div class="d-flex"> -->
                <v-icon color="success darken-1" size="80"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <v-card-title class="black--text d-flex justify-center mt-2"
                  >Upload Successful!</v-card-title
                >
                <!-- </div> -->
                <v-card-subtitle
                  >{{ `${filesUploaded}` }}
                  {{ filesUploaded > 1 ? 'files were ' : 'file was ' }} sent to the Sentry Health
                  team.</v-card-subtitle
                >
              </div>
              <div class="d-flex justify-center">
                <v-btn class="mt-4 pa-6" color="primary" @click="goBack">Upload another file</v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
  <!-- Login Section (Removed May 31 2022) -->
  <div v-else>
    <v-form @submit.prevent="compareHash" :disabled="disabled">
      <v-card class="my-12 mx-auto text-center primary--text" width="500" outlined>
        <h1 class="text-capitalize mt-12">Upload Password</h1>
        <v-card-text class="error-text">
          <p class="mb-6">
            This is a secure upload area.
            <br />
            Please contact
            <a color="primary" href="mailto:hello@sentryhealth.ca">hello@sentryhealth.ca</a> if you
            require assistance.
          </p>
          <v-text-field
            class="px-16"
            hide-details
            label="Password"
            name="password"
            outlined
            required
            type="password"
            v-model="password"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <div>
            <template v-if="errorMessage">
              <small class="error--text">{{ errorMessage }}</small>
              <br />
            </template>
            <v-btn type="submit" color="primary" :disabled="!password || disabled"> Go </v-btn>
          </div>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>
<style lang="scss">
.input-container {
  position: relative;
  width: 100%;
}

.input-container .v-btn {
  position: relative;
  padding: 0 !important;
  .v-btn__content {
    padding: 0 16px !important;
  }
}

.input-container .v-input {
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  .v-input__prepend-outer {
    display: none;
  }
  .v-input__slot {
    margin: -10px 0px !important;
  }
  .v-text-field__details {
    display: none;
  }
}

.input-container .v-input__control {
  opacity: 0 !important;
  visibility: none;
}
.input-container .v-icon {
  display: none;
}

.input-container .v-file-input__text {
  margin-right: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-container .v-input__append-inner {
  display: none;
}

.uploader {
  align-items: center;
  border: 1px dashed var(--v-accent-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  opacity: 1;
  /* padding: 6.5rem 0; */
}

.uploader--drop {
  border-color: var(--v-primary-base);
  color: var(--v-primary-base) !important;
  opacity: 0.6;
}

.uploader > .v-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.uploader--drop > .v-icon {
  color: var(--v-primary-base) !important;
}
</style>
<script>
import bcrypt from 'bcryptjs';

import { DATE_FORMAT } from '../constants/moment';
import { PUBLIC_HASH } from '../constants/env';
import * as masks from '../constants/masks';
import ERRORS from '../constants/errors';
import GET_UPLOAD_URL from '../graphql/Query/GetSignedUploadUrl.gql';

import LogoImage from '../components/LogoImage.vue';

const defaultUpload = {
  dob: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  hcn: '',
};

const defaultState = () => {
  return {
    disabled: false,
    errorMessage: '',
    errorFiles: [],
    files: [],
    filesUploaded: 0,
    fileUploaded: false,
    isDropping: false,
    password: '',
    visible: false,
    loading: false,
    newUpload: {
      ...defaultUpload,
    },
  };
};

const ACCEPTED_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export default {
  name: 'PublicUploads',
  data() {
    return {
      ...defaultState(),
      ACCEPTED_FILE_TYPES,
      masks,
      providerId: null,
      optionalFields: ['hcn'],
    };
  },
  components: { LogoImage },
  mounted() {
    this.resetState();
    const { providerId } = this.$route.query;
    if (!providerId) {
      this.$router.push({ name: 'Login' });
      this.$store.commit('setNotification', {
        color: 'error',
        text: "You don't have the necessary info to use this.",
        timeout: 3000,
      });
      return;
    }

    this.providerId = providerId;
  },
  methods: {
    addFile(e) {
      this.isDropping = false;
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles?.length) return;
      this.files = [...this.files, ...droppedFiles];
    },
    async compareHash() {
      this.errorMessage = '';

      if (await bcrypt.compare(this.password, PUBLIC_HASH.replace(/\?/g, '$'))) {
        this.visible = true;
      } else {
        this.errorMessage = ERRORS.hash.desc;
      }
    },
    async doUpload() {
      this.loading = true;
      const operations = [];
      this.files.forEach((file) => {
        operations.push(this.uploadFile(file));
      });
      await Promise.all(operations);
      this.sendGChatNotification();
      this.fileUploaded = true;
    },
    getFileNames(files) {
      if (!files?.length) return '';
      return files
        .reduce((allNames, currentFile) => {
          const { name } = currentFile;
          const { length, [length - 1]: extension } = name.split('.');
          const isBig = name.length > 20;
          const fileName = `${name
            .substring(0, Math.min(20, name.length))
            .replace(/-|_|%20/g, ' ')
            .toLowerCase()}${isBig ? '...' : '.'}${extension}`;
          return [...allNames, fileName];
        }, [])
        .join(', ');
    },
    goBack() {
      this.resetState();
    },
    onFileChange(allFiles) {
      this.files = allFiles.filter((file) => ACCEPTED_FILE_TYPES.includes(file.type));
      this.errorFiles = allFiles.filter((file) => !ACCEPTED_FILE_TYPES.includes(file.type));
    },
    resetState() {
      Object.assign(this, defaultState());
    },
    sendGChatNotification() {
      const http = new XMLHttpRequest();
      http.open(
        'POST',
        // eslint-disable-next-line max-len
        'https://chat.googleapis.com/v1/spaces/AAAAdw1tiSk/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=IKHR_dSp-y2zEyltkLjturw9VBxnebxq0a1y-vtQ9rY%3D',
        true
      );

      // Send the proper header information along with the request
      http.setRequestHeader('Content-type', 'application/json');

      http.send('{ "text": "Upload Successful" }');
    },
    async getSignedUploadUrl(file) {
      const { providerId } = this;
      try {
        const result = await this.$apollo.query({
          query: GET_UPLOAD_URL,
          variables: {
            providerId,
            fileName: file.name,
            mimetype: file.type,
            metaData: Object.entries({
              ...this.newUpload,
              dob: this.$store.getters.toUTC(this.newUpload.dob, DATE_FORMAT).unix(),
            }).map(([key, value]) => {
              const vars = {
                key,
              };
              if (Number.isInteger(value)) {
                vars.valueFloat = value;
              } else {
                vars.valueString = value;
              }

              return vars;
            }),
          },
          fetchPolicy: 'no-cache',
        });
        return result.data.getSignedUploadUrl;
      } catch (e) {
        throw new Error(e);
      }
    },
    async uploadFile(file) {
      try {
        const url = await this.getSignedUploadUrl(file);
        if (!url) throw new Error();
        const formData = new FormData();
        formData.append('', file, file.name);
        const headers = new Headers();
        headers.append('x-goog-resumable', 'start');

        const requestOptions = {
          method: 'POST',
          body: formData,
          redirect: 'follow',
          mode: 'no-cors',
          headers,
        };

        await fetch(url, requestOptions);
        this.filesUploaded += 1;
      } catch {
        this.$store.commit('setNotification', {
          color: 'error',
          text: 'Unable to upload the file. Try again.',
          timeout: 3000,
        });
      }
    },
  },
  computed: {
    errorFileNames() {
      return this.getFileNames(this.errorFiles);
    },
    fileName() {
      return this.getFileNames(this.files);
    },
    informationMissing() {
      return Object.entries(this.newUpload)
        .filter(([key, value]) => !this.optionalFields.includes(key) && !value)
        .map(([key]) => key);
    },
    missingFields() {
      const fields = [];
      if (this.loading) {
        fields.push('Uploading file...');
        return fields;
      }
      if (!this.files?.length) {
        fields.push('Missing a file to upload.');
      }
      if (this.informationMissing.length) {
        const fieldsText = {
          firstName: 'first name',
          lastName: 'last name',
          email: 'email',
          dob: 'date of birth',
          hcn: 'health card number',
          phone: 'phone number',
        };
        this.informationMissing.forEach((field) => {
          fields.push(`Missing ${fieldsText[field]}.`);
        });
      }
      return fields;
    },
    uploadDisabled() {
      const { informationMissing } = this;
      return !!informationMissing.length || !this.files?.length;
    },
  },
};
</script>
